export const environment = {
    production: false  ,
     serverUrl: 'http://10.240.33.9:7000',
     logoutUrl:'http://10.240.38.38:32003/',
     keycloak: {
       // Url of the Identity Provider
      // issuer: 'http://cnp-robin-prod1.ocitnetad.ci:30502',
      issuer: 'http://10.240.38.37:30502/',
       // Realm
       realm: 'tracking-cash',
       clientId: 'tracking-cash-frontend-cli',
       
     },
  };