import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class BaseService {
    apiUrl = environment.serverUrl;
    constructor(public http: HttpClient) {}

    public createCompleteRoute = (route: string, envAddress: string) => {
        return `${envAddress}/${route}`;
    }

    getData(route: string) {
        return this.http.get(this.createCompleteRoute(route, this.apiUrl));
    }

    postData(route: string, file: any) {
        return this.http.post(this.createCompleteRoute(route,this.apiUrl), file);
    }
}