<div class="flex justify-content-center">
  <p-sidebar [(visible)]="sidebarVisible">
      <div class="menu-wrapper">
        <ul style="list-style-type: none;">
        <li class="menu-item"><a routerLinkActive="active" routerLink="/home">Accueil</a></li>
        <li class="menu-item"><a routerLinkActive="active" routerLink="/admin/profil">Mon profil</a></li>
        <li class="menu-item"><a routerLinkActive="active" routerLink="/dashboard">Dashboard</a></li>
        <li class="menu-item"><a routerLinkActive="active" routerLink="/admin/utilisateurs">Utilisateurs</a></li>
        <li class="menu-item"><a routerLinkActive="active" routerLink="/equipes">Equipes</a></li>
        <li class="menu-item"><a routerLinkActive="active" routerLink="#">Formulaires</a></li>
        <li class="menu-item"><a routerLinkActive="active" routerLink="#">Activités</a></li>
      </ul>
      </div>
  </p-sidebar>
  <a class="menutoogleIcon" (click)="sidebarVisible = true" >☰</a>
</div>