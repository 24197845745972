import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { HttpClientModule } from '@angular/common/http';
import { PaginatorModule } from 'primeng/paginator';
import { DialogModule } from 'primeng/dialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ToastModule } from 'primeng/toast';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializer } from './core/utils/app-inits';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    TableModule,
    HttpClientModule,
    ButtonModule,
    PaginatorModule,
    DialogModule,
    ConfirmPopupModule,
    ToastModule ,
    KeycloakAngularModule
  ],
  providers: [ ],
  bootstrap: [AppComponent]
})
export class AppModule { }
