import { Injectable } from '@angular/core';
import {BaseService} from "./base.service";
import {HttpClient} from "@angular/common/http";
import { KeycloakService } from 'keycloak-angular';
import {map} from "rxjs/operators";



@Injectable({
  providedIn: 'root'
})
export class UserService  extends BaseService{

  constructor(private keycloak: KeycloakService ,public override http: HttpClient) {
    super(http);
  }


  getUsernameInkeycloackService(): string {

    return this.keycloak.getUsername();
  }

  logout(url:any) {
    this.keycloak.logout(url);
  }




}


