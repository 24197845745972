import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BasicLayoutComponent } from './basic-layout/basic-layout.component';
import { RouterModule } from '@angular/router';
import { BaseService } from './sdk/http/base.service';
import { SidebarModule } from 'primeng/sidebar';



@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    BasicLayoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SidebarModule
  ],
  exports: [CommonModule, HeaderComponent],
  providers: [BaseService],
})
export class CoreModule { }
