import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  isCollapsed = false;
  isCollapsed2 = false;

  @Input() isOpen: boolean = false;
  @Output() toggleHeaderMenu = new EventEmitter();

  sidebarVisible: boolean = false;
  
  ngOnInit(): void {
    
  }

  menuToggled() {
    this.isOpen = false;
    this.toggleHeaderMenu.emit(this.isOpen);
  }

}
