<header>
  <nav class="navbar navbar-dark bg-dark navbar-expand-lg" aria-label="Supervision" style="background-color:#602582 !important;">
    <div class="container-xxl">
      <div style="order: -2; margin-right: 20px;margin-bottom: 12px;"> <app-sidebar></app-sidebar> </div>
      <div class="navbar-brand me-auto me-lg-4" style="margin-top: 0px !important; margin-bottom: 8px !important;">
        <a class="stretched-link" href="#">
          <img src="./assets/img/energy_logo.png" style="width: 100px; height: auto;" alt="Supervision" loading="lazy">
        </a>
        <h4 class="title" style="color:#fff !important; font-size: 1.25rem !important; font-size: 1.25rem !important; margin: 10px 20px;">Trade Energy Performance</h4>
      </div>
      <!-- Navbar with links -->
      <div id="global-header-1.1" class="navbar-collapse collapse me-lg-auto global-header-1">
        <!--<ul class="navbar-nav">
          <li class="nav-item"><a class="nav-link active" href="#" aria-current="page">Recouvrement</a></li>
          <li class="nav-item"><a class="nav-link" href="#" aria-current="page">Historique des activités</a></li>
        </ul>-->
      </div>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDarkDropdown" aria-controls="navbarNavDarkDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>  
      <div class="collapse navbar-collapse" id="navbarNavDarkDropdown">
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <button class="nav-link dropdown-toggle" data-bs-toggle="dropdown" type="button" aria-expanded="false">
              <img style="border-radius: 50%;" src="./assets/avatar.png" width="25" height="25" role="img" alt="User" loading="lazy"> Bonjour
            </button>
            <ul class="dropdown-menu dropdown-menu-dark">
              <li><a class="dropdown-item" href="#">Déconnexion</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
