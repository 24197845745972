import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from '../sdk/http/users.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit{

  @Input() isOpen: boolean = false;
  @Output() toggleHeaderMenu = new EventEmitter();

  constructor(public userService: UserService,){

  }
  
  ngOnInit(): void {
    
  }

  menuToggled() {
    this.isOpen = true;
    this.toggleHeaderMenu.emit(this.isOpen);
  }



}
