import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BasicLayoutComponent } from './core/basic-layout/basic-layout.component';
import { HomeComponent } from './modules/home/home.component';

const routes: Routes = [
  {
    path:'', component: BasicLayoutComponent,
    children:[
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home' ,  loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)},
      { path: 'dashboard' ,  loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)},
      { path: 'admin' ,  loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)},
      { path: 'activites' ,  loadChildren: () => import('./modules/activites/activites.module').then(m => m.ActivitesModule)},
      { path: 'equipes' ,  loadChildren: () => import('./modules/equipes/equipes.module').then(m => m.EquipesModule)},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
